body {
  margin: 0;
  padding: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

/*login_register - index_login_register.jsx*/
.App {
  text-align: center;
  height: 100%;
}

.content {
  background-color: #ffffff;
  min-height: 90vh;
  display: grid;
  grid-template-rows: 20% 20% 15% 10% 15% 20%;
  grid-template-columns: 10% 80% 10%;
  -webkit-align-items: center;
          align-items: center;
  -webkit-justify-content: center;
          justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.registerLogin {
  grid-column-start:2; 
  grid-column-end:3;
  grid-row-start:2; 
  grid-row-end:5;
}

.lineBreakerStartForm {
  margin-bottom: 2vh;
}

.startScreenFooter {
  position: fixed;
  height: 9vh;
  left: 0;
  bottom: 0;
  width: 100%;
  background-color: rgb(255, 255, 255);
  color: rgb(10, 6, 6);
  -webkit-align-items: center;
          align-items: center
}

.table {
  -webkit-align-self: center;
          align-self: center;
}

.td {
  cursor: pointer;
}

/*route_page - notfound.jsx*/
.notFound {
  position:fixed;
  padding:0;
  margin:0;

  top:0;
  left:0;

  width: 100%;
  height: 100%;
  background:#90B134;

  text-align:center;

  display:table;
}

.notFound h1 {
    font-size: 60px;
    display:table-cell; 
    vertical-align:middle
}

/*1. Screen Overview Page*/
.contentOverviewPage {
  display: grid;
  min-height:90vh;
  grid-template-rows: 8% 20% 50% 15% 7%;
  grid-template-columns: 15% 70% 15%;
  justify-items: center;
  -webkit-align-items: center;
          align-items: center;
  -webkit-align-content: center;
          align-content: center;
}

.calendar {
  grid-column-start:2; 
  grid-column-end:3;
  grid-row-start:3; 
  grid-row-end:4;
  min-height: 400px;
}

.createNewSurvey {
  grid-column-start:2; 
  grid-column-end:3;
  grid-row-start:4; 
  grid-row-end:5;
  margin-top:55px;
}

.typoCalendar {
  margin-left: 3.4vh;
}

/*Userprofil*/
Button:focus {
  outline:none
}


.contentUserprofile {
  display: grid;
  min-height: 100vh;
  grid-template-rows: 20% 75% 5%;
  grid-template-columns: 20% 60% 20%;
  justify-items: center;
  -webkit-align-items: center;
          align-items: center;
  -webkit-align-content: center;
          align-content: center;
}


.formUserProfile {
  grid-column-start:2; 
  grid-column-end:3;
  grid-row-start:2; 
  grid-row-end:3;
}

.lineBreakerUserProfil {
  margin-bottom: 5vh;
}

/*Survey*/

/*Survey Description*/

.contentSurveyDescription {
  background-color: #ffffff;
  min-height: 90vh;
  display: grid;
  /*grid-template-rows: 20% 60% 20%;*/
  grid-template-rows: 40px 1fr 20%;
  grid-template-columns: 15% 70% 15%;
  -webkit-align-items: center;
          align-items: center;
  -webkit-justify-content: center;
          justify-content: center;
  font-size: calc(10px + 2vmin);
}

.surveyInformation {
  grid-column-start:2; 
  grid-column-end:3;
  grid-row-start:2; 
  grid-row-end:3;
  margin-bottom: 4vh;
  margin-top: 2vh;
}

.surveyInformationCount {
  grid-column-start:2; 
  grid-column-end:3;
  grid-row-start:1; 
  grid-row-end:1;
  font-size:17px;
  margin-bottom:5vh;
}

/*Survey Attendee Profil*/

.lineBreakerGender {
  margin-top: 5.8vh
}


/*Survey Questions*/

.contentSurveyQuestions {
  background-color: #ffffff;
  min-height: 90vh;
  display: grid;
  /*grid-template-rows: 10% 18% 62% 10%;*/
  grid-template-rows: 40px 1fr 20%;
  grid-template-columns: 15% 70% 15%;
  -webkit-align-items: center;
          align-items: center;
  -webkit-justify-content: center;
          justify-content: center;
  text-align: center;
  justify-items: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.surveyQuestions {
  height: 100%;
  margin-top: 6vh;
  grid-column-start:2; 
  grid-column-end:3;
  grid-row-start:2; 
  grid-row-end:3;
  margin-top: 5vh;
  margin-bottom: 3vh;
  text-align: center;
}

/*Survey Advice*/

.surveyHelpSmileyDiagram {
  display: -webkit-flex;
  display: flex;
  -webkit-justify-content: center;
          justify-content: center;
}

/*Intro Page Own Surveys*/

.introOwnSurveys {
  height: 100vh;
  text-align: center;
  margin-left: 5vh;
  margin-right: 5vh;
  margin-top: 13vh;
}

.textIntroOwnSurveys {
  margin-bottom: 5vh;
  font-weight:400;
  text-align:left;
}

.textInputLabel {
  text-align:left;
  width:200%;
}

/*Overview Own Surveys*/

  .overviewOwnSurveys {
    -webkit-justify-content: center;
            justify-content: center;
    margin-left: 10%;
    margin-right: 10%;
    margin-top: 15vh;
    margin-bottom: 5%;
  }




/*@media only screen and (min-width : 600px) {
   class of element you need to hide*/
  .overviewOwnSurveysSmallDevices {
    display:none;
  } 
/*}*/

/*Survey Creation Access Check*/

.screenAccessCheck {
  width: 100%;
  height: 100vh;
  display: grid;
  grid-template-rows: 6.5% 30% 33.5% 30%;
  grid-template-columns: 15% 70% 15%;
}

.headerReview {
  width: 100%;
  height: 6.5%;
  grid-column-start:1; 
  grid-column-end:4;
  grid-row-start:1; 
  grid-row-end:2;
}

.contentAccessCheck {
  width: 100%;
  height: 93.5%;
  grid-column-start:2; 
  grid-column-end:3;
  grid-row-start:3; 
  grid-row-end:3;
}

.inlineCodeSurveySet {
  display: -webkit-flex;
  display: flex;
}

/*Survey Creation*/

.surveyCreationForm {
  -webkit-justify-content: center;
          justify-content: center;
  margin-left: 10%;
  margin-right: 10%;
  margin-top: 15vh;
  margin-bottom: 5%;
}

.idSurveySet {
  margin-top:2vh;
}

.surveyCreationSurveyDetails {
  margin-bottom: 2%;
}

.surveyCreationSurveyDataForm {
  margin-bottom: 2%;
  padding-left:50px;
}

.surveyCreationProfileDataForm {
  margin-bottom: 2%;
  padding-left:50px;
}

.SurveyCreation-formControl-9 formControl {
	margin-left:0px;
}

.surveyCreationInvitations {
  margin-bottom: 2%;
  padding-left:50px;
}

.surveyCreationInvitationsOverview {
  margin-bottom: 5%;
}

.RangeExampleSurveyCreation {
  margin-bottom: 2vh;
  margin-top: 2.6vh;
}

/*Statistic*/

.screenStatistic {
  width: 100%;
  min-width:520px;
  height: 100vh;
  display: grid;
  /*
  grid-template-rows: 6.5% 4% 25% 4% 50% 4% 6.5%;*/
  grid-template-columns: 8% 37% 10% 37% 8%;
  grid-template-rows: 60px 10px auto 50px 1fr 10px 60px
}

.headerStatistic {
  width: 100%;
  height: 6.5%;
  grid-column-start:1; 
  grid-column-end:6;
  grid-row-start:1; 
  grid-row-end:2;
}

.footerStatistic {
  position: fixed;
  left: 0;
  bottom: 0;
  width: 100%;
}

.contentStatistic1 {
  width: 100%;
  /*height: 93.5%;*/
  height:100%;
  position:relative;
  grid-column-start:2; 
  grid-column-end:5;
  grid-row-start:5; 
  grid-row-end:6;
  min-height:400px; 
  min-width:400px;
  margin-bottom:115px;
}

.statisticFilter1 {
  width: 100%;
  grid-column-start:2; 
  grid-column-end:3;
  grid-row-start:3; 
  grid-row-end:4;
}

.statisticFilter2 {
  width: 100%;
  grid-column-start:4; 
  grid-column-end:5;
  grid-row-start:3; 
  grid-row-end:4;
}



.filterContentDatePicker {
  margin-top: 2vh;
}

.compareStatistic {
  width: 100%;
  grid-column-start:3; 
  grid-column-end:4;
  grid-row-start:3; 
  grid-row-end:4;
  margin-top: 8vh;
}

.loadingChartPlaceholder {
	text-align:center;
	color:#757575;
	font-size:16px;
}

.inlineSurveyCreationInvite {
  margin-top: 5vh;
  display: -webkit-flex;
  display: flex;
}

.margin {
  margin-top: 0.8vh;
}

.statisticKidsConnectionText {
	text-align: center;
	padding-bottom: 20px;
}

/*Bootstrap change input border*/

.form-control:focus {
  border-color: #90B134;
  box-shadow: 0px 1px 1px rgb(0, 0, 0, 0.075) inset, 0px 0px 15px #90B134;
}

#input-group-dropdown-1 {
  margin-top: 2vh;
  border-color: #90B134;
  font-weight: bold;
}

#input-group-dropdown-1:hover {
  color: rgb(255, 255, 255);
  background-color:#90B134;
}

.statsNotAvailable {
  text-align: center;
  margin-top: 10%;
}

/*Statistic Kids*/

.statisticKids {
  display: grid;
  min-height:90vh;
  grid-template-rows: 10% 80% 10%;
  grid-template-columns: 8% 84% 8%;
  justify-items: center;
  -webkit-align-items: center;
          align-items: center;
  text-align: center;
}

.contentStatisticKids {
  grid-column-start:2; 
  grid-column-end:3;
  grid-row-start:2; 
  grid-row-end:3;
  text-align:left;
}

/* Participation without Login*/


.contentWithoutLogin {
  min-height: 90vh;
  display: grid;
  grid-template-rows: 20% 20% 15% 10% 15% 20%;
  grid-template-columns: 10% 25% 30% 25% 10%;
  -webkit-align-items: center;
          align-items: center;
  -webkit-justify-content: center;
          justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.registerWithoutLogin {
  grid-column-start:3; 
  grid-column-end:4;
  grid-row-start:2; 
  grid-row-end:5;
}

.link {
  color: #90B134;
  text-decoration: none;
  font-size: 16px;
}

.inlinePrivacyStatement {
  display:-webkit-flex;
  display:flex;
  max-width: 65vh;
}


/*Statistic Diagram for the kids*/

.barhoriz {
  width: 100%;
}

.typoDescriptionOverviewStats {
  margin-bottom: 4vh;
}

.hprogressHappy {
  background-color: #90B134;
  color: #ffffff;
  height: 7vh;
  margin-bottom: 2vh;
  margin-right: 2vh;
  font-size: 28px;
  border-radius: 20px;
  text-align: left;
  max-height: 48px;
}

.hprogressFriendly {
  background-color: #CBCF00;
  color: #ffffff;
  height: 7vh;
  margin-bottom: 2vh;
  margin-right: 2vh;
  font-size: 28px;
  border-radius: 20px;
  text-align: left;
  max-height: 48px;
}

.hprogressNeutral {
  background-color: #F4Dc00;
  color: #ffffff;
  height: 7vh;
  margin-bottom: 2vh;
  margin-right: 2vh;
  font-size: 28px;
  border-radius: 20px;
  text-align: left;
  max-height: 48px;
}

.hprogressBad {
  background-color: #F69B00;
  color: #ffffff;
  height: 7vh;
  margin-bottom: 2vh;
  margin-right: 2vh;
  font-size: 28px;
  border-radius: 20px;
  text-align: left;
  max-height: 48px;
}

.hprogressSad {
  background-color: #F10000;
  color: #ffffff;
  height: 7vh;
  margin-bottom: 2vh;
  margin-right: 2vh;
  font-size: 28px;
  border-radius: 20px;  
  text-align: left;
  max-height: 48px;
}

#textHProgress {
  padding-left: 1vh;
}

.inlineStatisticKids {
  display: -webkit-flex;
  display: flex;
}

/*correlationAnalysis Kids*/

.correlationAnalysis {
  display: inline-block;
  margin-top: 5vh;
}

.negativeAnalysis {
  /*display: inline-block;*/
  display:grid;
  margin-top: 4vh;
    display: grid;
  
  grid-template-rows: 100%;
  grid-template-columns:60px 85%;
 
}

.infoAnalysis {
  margin-top: 6vh;
}

.typoHeadline {
  margin-bottom: 1vh;
}


/*test*/
.connectedQuestions {
  border-width: 2px;
  border-color: grey;
  border-style: double;
  border-radius: 20px;
  padding: 10px;
  padding-bottom: 20px;
}


.connectedQuestionsNegative {
  border-color: orange;
}

.connectedQuestionsPositive {
  border-color: #90B134;
}


.testover{ margin:20%;} /* foo is class of element you need to hide*/
 
.loading {
  position: absolute;
  top:0;
  bottom: 0;
  right: 0;
  left: 0;
  display: -webkit-flex;
  display: flex;
  -webkit-justify-content: center;
          justify-content: center;
  -webkit-align-items: center;
          align-items: center;    
  z-index: 10;

 
}

/*Daily Stats*/

.listDailyStats {
  list-style-type: none;
}

.dailyStats {
  margin: 10%;  
}

.displayText {
  font-size: 20px;
}

.answersDropdown {
  font-size: 18px;
  color: #90B134;
}

.answersDropdownAnd {
  font-size: 18px;
  color: #90B134;
}

.textAnswers {
  font-size: 17px;
  color: #90B134;
}


/*formalities*/

.compute {
  /*text-align: center;*/
  padding-top: 13vh;
  margin-left: 5vh;
  margin-right: 5vh;
}

.Impressum {
  text-align: left;
  margin-left: 5vh;
  margin-right: 5vh;
  padding-top: 13vh;
}

.privacyStatement {
  padding-top: 13vh;
  margin-left: 5vh;
  margin-right: 5vh;

}

.statsReloadedGoBack {
  margin: 25%;
}
/*
.centerDropdown {
  max-width: 100px;
  margin-left: 38%;
}*/

.centerDropdown {       
  display:inline-block;
  max-width:100%;
  margin-left:0;
}

.ratingImage {
	width:40px;
	margin-right:5px;
}


ul {
	padding-left:0;
}

.surveyCreationInvitations ul {
	/*padding-left:48px;*/
	list-style-type:circle;
}

.dsgvoInfo {
	width:460px;
}

.MuiButton-label {
	font-size:16px;
}

.MuiFab-label {
	font-size:16px;
}

.MuiInputLabel-shrink {
  text-align:left;
  width: 150%;
}

.link {
	font-size:16px;
}

.statsRatingImg {
	max-width:40px;
	max-height:40px;
}

.statsRatingImgBar {
	max-width:45px;
	max-height:45px;
}

.negativeAnalysis {
	margin-bottom:20px;
}

.positiveAnalysis {
	margin-bottom:20px;
	display:grid;
	grid-template-rows: 100%;
    grid-template-columns:60px 85%;
}

.histogramTooltip {
	width:200px;
	padding:5px;
}

#ratingImageDim {
	position:absolute;
	top: 7px;
	left:0%;
	right:-20px;
	height:54vh;
	display: -webkit-flex;
	display: flex;
	-webkit-flex-direction: row;
	        flex-direction: row;
	-webkit-justify-content:space-between;
	        justify-content:space-between;
	-webkit-align-items: flex-end;
	        align-items: flex-end;
	padding-left:100px;
	min-height:440px;
}

#ratingImageFreq {
	position:absolute;
	top: 7px;
	left:0%;
	width:92%;
	height:54vh;
	/*height:1vh;*/
	display: -webkit-flex;
	display: flex;
	-webkit-flex-direction: row;
	        flex-direction: row;
	-webkit-justify-content:space-between;
	        justify-content:space-between;
	-webkit-align-items: flex-end;
	        align-items: flex-end;
	padding-left:11%;
	min-height:400px;
	/*margin-top: 52vh;*/
}

#ratingImageHistory {
	position:absolute;
	top: 0px;
	left:-5px;
	height:46.0vh;
	width:40px;
	display: -webkit-flex;
	display: flex;
	-webkit-flex-direction: column-reverse;
	        flex-direction: column-reverse;
	-webkit-justify-content:space-between;
	        justify-content:space-between;
	padding-left:0%;
	padding-top:35px;
	min-height:360px;
	padding-bottom:5vh;
}

.chartHeaderTitle {
	color:#757575;
	font-size:16px;
}


@media screen and (max-width: 480px) {
	.ratingImage {
		max-width:26px;
		margin-right:-2px;
	}
	
	#ratingImageHistory {
		padding-top:45px;
		padding-bottom:40px;
	}
	
	#ratingImageFreq {
		width:93%;
		padding-left:15%;
		padding-bottom:20px;
	}
	
	#ratingImageDim {
		right:-10px;
		padding-left:110px;
		padding-bottom:25px;
	}
	
	.statsRatingImg {
		max-width:20px;
		max-height:20px;
		bottom:0px;
		
	}
	
	.statsRatingImgBar {
		max-width:40px;
		max-height:40px;
	}
	
	.dsgvoInfo {
		width:80%;
	}
	
	.PrivateRadioButtonIcon-root-473 {
		max-width:20px;
	}
	
	.MuiButton-label {
		font-size:12px;
	}
	
	.MuiFab-label {
		font-size:12px;
	}
	
	.link {
		font-size:12px;
	}
	
	.contentSurveyDescription {
	  background-color: #ffffff;
	  min-height: 90vh;
	  display: grid;
	  grid-template-rows: 40px 1fr 20%;
	  grid-template-columns: 15% 70% 15%;
	  -webkit-align-items: center;
	          align-items: center;
	  -webkit-justify-content: center;
	          justify-content: center;
	  font-size: calc(10px + 2vmin);
	}
	
	.registerWithoutLogin {
		  grid-column-start:2; 
		  grid-column-end:5;
		  grid-row-start:2; 
		  grid-row-end:5;
  }
	
}
